import React, { useContext, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./WhoAreWe.style";
import { ExistenceValidationType } from "@interfaces";
import { ButtonPrimary, EmailInput } from "@components";
import teamMembers from "./TeamMembers";
import { SaveByRokinContext } from "@context";
import { WHY_ROKIN_TITLE, WHY_ROKIN_TITLE_2LINES, THE_TEAM } from "images/GCP";
import { int } from "aws-sdk/clients/datapipeline";

const WhoAreWe = () => {
  const isMobile = useMediaQuery("(max-width: 1279px)");
  const isSmallMobile = useMediaQuery("(max-width: 500px)");
  const classes = useStyles();
  const videoUrl = "bf_GrYEz8VY";
  const { form, handleChange, isAlreadyCreated, finishRegister } =
    useContext(SaveByRokinContext);
  const [invalidEmail, setIsInvalidEmail] = useState(false);
  const disableContinueButton =
    invalidEmail || !form.emailUser || isAlreadyCreated;

  const ColumnOfTeamMembersHandlerDesktop = () => {
    const columns = [];
    let starter = 0;
    for (let i = 0; i < teamMembers.length / 4; i++) {
      columns[i] = (
        <div className={classes.rowOfTeamMembers}>
          {rowOfTeamMembersHandlerDesktop(starter)}
        </div>
      );
      starter += 4;
    }
    return <>{columns}</>;
  };

  const rowOfTeamMembersHandlerDesktop = (starter: int) => {
    const member = [];
    for (let i = starter; i < starter + 4; i++) {
      if (i < teamMembers.length) {
        member[i] = (
          <div className={classes.teamMember}>
            <img src={teamMembers[i].emp_picture} alt="" />
            <p className={classes.nameText}>{teamMembers[i].emp_name}</p>
            <p className={classes.positionText}>{teamMembers[i].emp_desg}</p>
          </div>
        );
      }
    }
    return <>{member}</>;
  };

  const ColumnOfTeamMembersHandlerMobile = () => {
    const columns = [];
    let starter = 0;
    for (let i = 0; i < teamMembers.length / 2; i++) {
      columns[i] = (
        <div className={classes.rowOfTeamMembers}>
          {rowOfTeamMembersHandlerMobile(starter)}
        </div>
      );
      starter += 2;
    }
    return <>{columns}</>;
  };

  const rowOfTeamMembersHandlerMobile = (starter: int) => {
    const member = [];
    for (let i = starter; i < starter + 2; i++) {
      if (i < teamMembers.length) {
        member[i] = (
          <div className={classes.teamMember}>
            <img src={teamMembers[i].emp_picture} alt="" />
            <p className={classes.nameText}>{teamMembers[i].emp_name}</p>
            <p className={classes.positionText}>{teamMembers[i].emp_desg}</p>
          </div>
        );
      }
    }
    return <>{member}</>;
  };
  return (
    <>
      <>
        {!isMobile && (
          <>
            <div className={classes.MainContainer}>
              <div className={classes.videoTextContainer}>
                <div className={classes.textContainer}>
                  <h1>
                    {" "}
                    <img src={WHY_ROKIN_TITLE} alt="" />
                  </h1>
                  <p className={classes.paragraphText}>
                    Nos cansamos de no entender finanzas y pagar de más.
                    Queremos conocer el impacto financiero de cada decisión en
                    nuestra vida, sin sorpresas.
                  </p>
                  <p className={classes.paragraphText}>
                    Por eso, simplificamos la relación con las finanzas
                    personales, para vivir mas tranquilos y disfrutar cada
                    decisión que tomamos.
                  </p>
                </div>
                <div className={classes.videoRectangle}>
                  <iframe
                    width="auto"
                    height="auto"
                    src={`https://www.youtube.com/embed/${videoUrl}?autoplay=1&loop=1&autopause=0&muted=1&rel=0`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
                </div>
              </div>

              <div className={classes.theTeamTitle}>
                <h2>
                  <img src={THE_TEAM} alt="" />
                </h2>
              </div>

              <div className={classes.teamContainer}>
                {ColumnOfTeamMembersHandlerDesktop()}
              </div>

              <div className={classes.contactContainer}>
                <div className={classes.email}>
                  <p className={classes.emailText}>
                    Contáctanos y únete al equipo Rokin!
                  </p>
                  <div className={classes.emailInputAndButton}>
                    <EmailInput
                      name="emailUser"
                      onChange={handleChange}
                      value={form.emailUser}
                      onErrorChange={setIsInvalidEmail}
                      validateExistenceInSAVE={
                        ExistenceValidationType.MUST_NOT_EXISTS
                      }
                    />
                    <ButtonPrimary
                      onClick={finishRegister}
                      text=""
                      noDegraded
                      disabled={disableContinueButton}
                      startIcon={
                        <img src="https://rokinapp-images.s3.amazonaws.com/icons/flecha_icon.svg" />
                      }
                    />
                  </div>
                  {isAlreadyCreated && (
                    <section className={classes.mensaje}>
                      ¡Ya estás inscrito para el lanzamiento de Rokin!
                    </section>
                  )}
                </div>{" "}
              </div>
            </div>
          </>
        )}

        {isMobile && (
          <>
            <div className={classes.MainContainer}>
              <div className={classes.videoRectangleMobile}>
                <iframe
                  width="auto"
                  height="auto"
                  src={`https://www.youtube.com/embed/${videoUrl}?autoplay=1&loop=1&autopause=0&muted=1&rel=0`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
              <div className={classes.textContainer}>
                {isSmallMobile && (
                  <h1>
                    <img src={WHY_ROKIN_TITLE_2LINES} alt="" />
                  </h1>
                )}
                {!isSmallMobile && (
                  <h1>
                    <img src={WHY_ROKIN_TITLE} alt="" />
                  </h1>
                )}
                <p className={classes.paragraphText}>
                  Nos cansamos de no entender finanzas y pagar de más. Queremos
                  conocer el impacto financiero de cada decisión en nuestra
                  vida, sin sorpresas.
                </p>
                <p className={classes.paragraphText}>
                  Por eso, simplificamos la relación con las finanzas
                  personales, para vivir mas tranquilos y disfrutar cada
                  decisión que tomamos.
                </p>
              </div>

              <div className={classes.theTeamTitle}>
                <h2>
                  <img src={THE_TEAM} alt="" />
                </h2>
              </div>

              <div className={classes.teamContainer}>
                {ColumnOfTeamMembersHandlerMobile()}
              </div>
            </div>

            <div className={classes.right}>
              <div className={classes.innerRight}>
                <div className={classes.subtitle2}>
                  <span>Contáctanos y únete al equipo de Rokin!</span>
                </div>
                <div className={classes.email2}>
                  {" "}
                  <EmailInput
                    name="emailUser"
                    onChange={handleChange}
                    value={form.emailUser}
                    onErrorChange={setIsInvalidEmail}
                    validateExistenceInSAVE={
                      ExistenceValidationType.MUST_NOT_EXISTS
                    }
                  />
                  <ButtonPrimary
                    onClick={finishRegister}
                    text=""
                    noDegraded
                    disabled={disableContinueButton}
                    startIcon={
                      <img src="https://rokinapp-images.s3.amazonaws.com/icons/flecha_icon.svg" />
                    }
                  />
                </div>
                {isAlreadyCreated && (
                  <section className={classes.mensaje}>
                    ¡Ya estás inscrito para el lanzamiento de Rokin!
                  </section>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default WhoAreWe;
