import {
  MARION_FRAME,
  TU_FRAME,
  VICENTE_FRAME,
  WANDA_FRAME,
  CRISTOBAL_FRAME,
  JOAQUIN_E_FRAME,
  JOAQUIN_S_FRAME,
  JOSE_FRAME,
  AGUSTIN_FRAME,
  ALEJANDRO_FRAME,
  CARLOS_FRAME,
  NICOLE_FRAME,
} from "images/GCP";

let teamMembersArray: {
  emp_id: number;
  emp_name: string;
  emp_desg: string;
  emp_picture: string;
}[] = [
  {
    emp_id: 0,
    emp_name: "MARION EICHHORN",
    emp_desg: "Product Manager",
    emp_picture: MARION_FRAME,
  },
  {
    emp_id: 1,
    emp_name: "JOAQUIN ERRÁZURIZ",
    emp_desg: "Developer",
    emp_picture: JOAQUIN_E_FRAME,
  },
  {
    emp_id: 2,
    emp_name: "CRISTÓBAL OJEDA",
    emp_desg: "CEO",
    emp_picture: CRISTOBAL_FRAME,
  },
  {
    emp_id: 3,
    emp_name: "JOSÉ TOMÁS OTERO",
    emp_desg: "CLO",
    emp_picture: JOSE_FRAME,
  },
  {
    emp_id: 4,
    emp_name: "VICENTE OVALLE",
    emp_desg: "Sales Manager",
    emp_picture: VICENTE_FRAME,
  },
  {
    emp_id: 5,
    emp_name: "ALEJANDRO ROMERO",
    emp_desg: "Developer",
    emp_picture: ALEJANDRO_FRAME,
  },
  {
    emp_id: 6,
    emp_name: "WANDA SCARPATO",
    emp_desg: "Developer",
    emp_picture: WANDA_FRAME,
  },
  {
    emp_id: 7,
    emp_name: "JOAQUÍN SOZA",
    emp_desg: "Developer",
    emp_picture: JOAQUIN_S_FRAME,
  },
  {
    emp_id: 8,
    emp_name: "NICOLE TRONCOSO",
    emp_desg: "Head of Design",
    emp_picture: NICOLE_FRAME,
  },
  {
    emp_id: 9,
    emp_name: "CARLOS VALDÉS",
    emp_desg: "CFO",
    emp_picture: CARLOS_FRAME,
  },
  {
    emp_id: 10,
    emp_name: "AGUSTÍN ZALAQUETT",
    emp_desg: "Developer",
    emp_picture: AGUSTIN_FRAME,
  },
  {
    emp_id: 11,
    emp_name: "TU (?)",
    emp_desg: "‎",
    emp_picture: TU_FRAME,
  },
];

export default teamMembersArray;
