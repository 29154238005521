import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme, {}>((theme) => ({
  MainContainer: {
    padding: "6rem 0rem 0 0",
    [theme.breakpoints.up(1600)]: {
      padding: "6rem 0rem 0 0",
    },
    [theme.breakpoints.down(1280)]: {
      padding: "1.5rem",
    },
  },
  videoTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  textContainer: {
    width: "26%",
    margin: "0 2% 0 4%",
    [theme.breakpoints.up(1600)]: {
      width: "27rem",
      margin: "0 13% 0 7%",
    },
    [theme.breakpoints.down(1280)]: {
      width: "100%",
      margin: "0",
    },
    [theme.breakpoints.up(500)]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    [theme.breakpoints.up(1279)]: {
      display: "block",
    },
    "& img": {
      [theme.breakpoints.down(1279)]: {
        alignSelf: "center",
        display: "flex",
        margin: "2.5rem 0 0 10%",
      },
      [theme.breakpoints.down(500)]: {
        alignSelf: "left",
        margin: "2.5rem 0 0 15%",
      },
    },
  },
  mainTitle: {},
  paragraphText: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.75,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#7d7c7c",
    margin: "2.3rem 0 0 0",
    [theme.breakpoints.down(1280)]: {
      fontSize: "0.875rem",
      margin: "1.5rem 4rem 0 4rem",
    },
  },
  videoRectangle: {
    width: "52.25rem",
    height: "29.313rem",
    margin: "0",

    "& iframe": {
      width: "-webkit-fill-available",
      height: "-webkit-fill-available",
    },
  },
  videoRectangleMobile: {
    width: "20.938rem",
    height: "11.746rem",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    margin: "2.25rem auto 0",

    [theme.breakpoints.up(700)]: {
      width: "30.938rem",
      height: "19.746rem",
    },
    [theme.breakpoints.up(1000)]: {
      width: "40.938rem",
      height: "27.746rem",
    },
    "& iframe": {
      width: "-webkit-fill-available",
      height: "-webkit-fill-available",
    },
  },
  theTeamTitle: {
    display: "flex",
    justifyContent: "center",
    margin: "15rem 0 4.5rem 0",
    [theme.breakpoints.down(1280)]: {
      margin: "4rem 0 0 0",
      paddingBottom: "0rem",
    },
    "& img": {
      [theme.breakpoints.down(500)]: {
        width: "200px",
      },
    },
  },
  teamContainer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "8rem",
    [theme.breakpoints.down(1280)]: {
      paddingBottom: "0rem",
    },
  },
  teamMember: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    flexGrow: 0,
    [theme.breakpoints.down(1280)]: {
      width: "50%",
    },
    "& img": {
      alignSelf: "center",
      [theme.breakpoints.down(1280)]: {
        width: "6.25rem",
      },
    },
  },
  rowOfTeamMembers: {
    display: "flex",
    flexDirection: "row",
    margin: "4.8rem 0 0 0",
    paddingBottom: "1rem",
    [theme.breakpoints.down(1280)]: {
      paddingBottom: "0rem",
      margin: "2.8rem 0 0 0",
    },
  },
  nameText: {
    textAlign: "center",
    fontFamily: "Kiona, Montserrat",
    fontSize: "1.125rem",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    color: "#6b6b6b",
    [theme.breakpoints.down(1280)]: {
      fontSize: "0.875rem",
    },
  },
  positionText: {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.25,
    letterSpacing: "normal",
    color: "#9d9d9d",
    [theme.breakpoints.down(1280)]: {
      fontSize: "0.75rem",
    },
  },
  positionTextHidden: {
    textAlign: "center",
    opacity: 0,
  },
  email: {
    display: "flex",
    margin: "0 auto",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#001E28",
    width: "30.447rem",
    height: "13rem",
    alignItems: "center",
    borderRadius: "20.5px",
    [theme.breakpoints.down(1279)]: {
      width: "100%",
      borderRadius: "15px",
    },
    "& svg": { color: "white" },
    "& input": {
      color: "white",
    },
    "& button": {
      minWidth: "3rem",
      padding: "0.5rem 0 0.5rem 0.5rem",
      marginLeft: "1rem",
      height: 50,
      backgroundColor: "#084554!important",
      zIndex: 99,
      [theme.breakpoints.down(1240)]: {
        marginLeft: "0rem",
      },
      "& span": {
        marginRight: "0",
      },
    },
    "& >div, fieldset": {
      "& >div": {
        background: theme.palette.primary.dark,
        borderRadius: 15,
      },
      color: "white",
      borderRadius: 12,
      borderColor: "white!important",
      display: "flex",
      [theme.breakpoints.down(1240)]: {
        width: "15rem",
        textAlign: "start",
        fontSize: "0.75rem",
      },
    },
  },
  email2: {
    display: "flex",
    margin: "0 auto",
    justifyContent: "center",
    "& button": {
      minWidth: "3rem",
      padding: "0.5rem 0 0.5rem 0.5rem",
      marginLeft: "1rem",
      height: 50,
      "& span": {
        marginRight: "0",
      },
    },
    "& >div >div, fieldset": {
      border: "1px solid white",
      width: "20rem",
      color: "white",
      borderRadius: 12,
      margin: "0 auto",
      borderColor: "white!important",
      display: "flex",
      [theme.breakpoints.down(1240)]: {
        width: "213px",
      },
    },
  },
  right: {
    background: "#084554",
    display: "grid",
    gap: "1.5rem",
    padding: "2.5rem 2.5% 1.5rem 2.5%",
    height: "15rem",
    [theme.breakpoints.down(1279)]: {
      maxWidth: "100%",
      margin: "0 auto",
      marginTop: "3.5rem",
    },
  },
  innerRight: {
    background: "#0d3038",
    height: "9rem",
    borderRadius: "15px",
  },
  subtitle2: {
    opacity: 1,
    margin: "1.5rem 0 1rem 1.3rem",
    fontFamily: "Montserrat",
    fontSize: "0.75rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff",
    [theme.breakpoints.down(1279)]: {
      maxWidth: "300px",
    },
    "& span": {
      fontWeight: "bold",
      opacity: 1,
    },
  },
  emailText: {
    color: "white",
    textAlign: "left",
    width: "100%",
    paddingLeft: "4rem",
    marginBottom: "2rem",
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.05",
    letterSpacing: "normal",
    [theme.breakpoints.down(1279)]: {
      paddingLeft: "0rem",
      marginBottom: "0rem",
    },
  },
  emailInputAndButton: {
    [theme.breakpoints.down(1279)]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  contactContainer: {
    backgroundColor: "#00323c",
    height: "24rem",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(1279)]: {
      height: "19rem",
      padding: "1rem",
    },
  },
  contact: {
    display: "flex",
    justifyContent: "center",
    color: "white",
    backgroundColor: "red",
    "& svg": { color: "white" },
    "& input": { color: "white" },
    "& button": {
      minWidth: "3rem",
      padding: "0.5rem 0 0.5rem 0.5rem",
      marginLeft: "1rem",
      height: 50,
      backgroundColor: "white",
      [theme.breakpoints.down(1240)]: {
        marginLeft: "0rem",
      },
      "& span": {
        marginRight: "0",
      },
    },
    "& >div, fieldset": {
      "& >div": {
        background: theme.palette.primary.dark,
        borderRadius: 15,
      },
      color: "white",
      borderRadius: 12,
      borderColor: "white!important",
      display: "flex",
      [theme.breakpoints.down(1240)]: {
        width: "15rem",
        textAlign: "start",
        fontSize: "0.75rem",
      },
    },
  },
  mensaje: {
    marginLeft: "1rem",
    width: "25rem",
    color: "white",
    [theme.breakpoints.down(1240)]: {
      fontSize: "0.7rem",
      width: "18rem",
    },
  },
}));
