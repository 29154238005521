import React from "react";
import { PageProps } from "gatsby";
import WhoAreWe from "@components/WhoAreWe/WhoAreWe";
import { SEO, LayoutBild } from "@components";

const AboutUs: React.FC<PageProps> = () => {
  return (
    <>
      <SEO
        title="Rokin - Quiénes somos"
        description="Simplificamos las finanzas para que vivas con tranquilidad y disfrutes cada decisión que tomas. ¡Conoce al equipo Rokin!"
      />
      <LayoutBild aboutUs key="Bottom">
        <WhoAreWe />
      </LayoutBild>
    </>
  );
};

export default AboutUs;
